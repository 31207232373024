import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { animated, useSpring } from '@react-spring/web';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import { TreeItem2Content, TreeItem2IconContainer, TreeItem2Label, TreeItem2Root } from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';

import { useTheme } from '@mui/material/styles';

const ITEMS = [
    {
        id: '1',
        label: 'Website',
        children: [
            { id: '1.1', label: 'Home', color: 'green' },
            { id: '1.2', label: 'AI Resume Checker', color: 'green' },
            { id: '1.3', label: 'AI Cover Letter', color: 'green' },
            { id: '1.4', label: 'Subscription Plans ', color: 'green' },
            { id: '1.5', label: 'Blog', color: 'green' },
        ],
    },
    { id: '4', label: 'About Us', color: 'blue' },
    { id: '5', label: 'Contact', color: 'blue' },
];

function DotIcon({ color }) {
    return (
        <Box sx={{ marginRight: 1, display: 'flex', alignItems: 'center' }}>
            <svg width={6} height={6}>
                <circle cx={3} cy={3} r={3} fill={color} />
            </svg>
        </Box>
    );
}

DotIcon.propTypes = {
    color: PropTypes.string.isRequired,
};

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
        },
    });

    return <AnimatedCollapse style={style} {...props} />;
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

function CustomLabel({ color, expandable, children, ...other }) {
    const theme = useTheme();
    const colors = {
        blue: (theme.vars || theme).palette.primary.main,
        green: (theme.vars || theme).palette.success.main,
    };

    const iconColor = color ? colors[color] : null;
    return (
        <TreeItem2Label {...other} sx={{ display: 'flex', alignItems: 'center' }}>
            {iconColor && <DotIcon color={iconColor} />}
            <Typography className="labelText" variant="body2" sx={{ color: 'text.primary' }}>
                {children}
            </Typography>
        </TreeItem2Label>
    );
}

CustomLabel.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf(['blue', 'green']),
    expandable: PropTypes.bool,
};

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const { id, itemId, label, disabled, children, ...other } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
        publicAPI,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const item = publicAPI.getItem(itemId);
    const color = item?.color;
    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)}>
                <TreeItem2Content
                    {...getContentProps({
                        className: clsx('content', {
                            expanded: status.expanded,
                            selected: status.selected,
                            focused: status.focused,
                            disabled: status.disabled,
                        }),
                    })}
                >
                    {status.expandable && (
                        <TreeItem2IconContainer {...getIconContainerProps()}>
                            <TreeItem2Icon status={status} />
                        </TreeItem2IconContainer>
                    )}

                    <CustomLabel {...getLabelProps({ color })} />
                </TreeItem2Content>
                {children && <TransitionComponent {...getGroupTransitionProps({ className: 'groupTransition' })} />}
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});

CustomTreeItem.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * If `true`, the item is disabled.
     * @default false
     */
    disabled: PropTypes.bool,
    /**
     * The id attribute of the item. If not provided, it will be generated.
     */
    id: PropTypes.string,
    /**
     * The id of the item.
     * Must be unique.
     */
    itemId: PropTypes.string.isRequired,
    /**
     * The label of the item.
     */
    label: PropTypes.node,
};

export default function CustomizedTreeView() {
    return (
        <Card
            variant="outlined"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                flexGrow: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}
        >
            <CardContent>
                <Typography component="h2" variant="subtitle2">
                    Product tree
                </Typography>
                <RichTreeView
                    items={ITEMS}
                    aria-label="pages"
                    multiSelect
                    defaultExpandedItems={['1', '1.1']}
                    defaultSelectedItems={['1.1', '1.1.1']}
                    sx={{
                        m: '0 -8px',
                        pb: '8px',
                        height: 'fit-content',
                        flexGrow: 1,
                        overflowY: 'auto',
                    }}
                    slots={{ item: CustomTreeItem }}
                />
            </CardContent>
        </Card>
    );
}
